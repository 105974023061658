import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      prefectures: [],
      duplicatedNameInfoList: [], //重複する名称情報
    };
  },

  methods: {
    // 処分事業者情報取得API   DISPOSER.DETAIL + {this.disposalCompanyId}
    getDisposerInfo(disposalCompanyId) {
      return axios
        .get(API_URL.DISPOSER.DETAIL + disposalCompanyId)
        .then((res) => {
          const data = res.data.disposalSiteList;
          this.validate = [];

          data.map((d, index) => {
            const newId = index + 1;
            const info = d.disposalSiteBaseInfo;
            const disposalSiteLicenceList = d.disposalSiteLicenceList.map(
              (licence) => {
                return { id: licence.id };
              }
            );
            const finalDisposalsiteList = d.finalDisposalSiteList.map(
              (finalDisposalSite) => {
                return {
                  finalDisposalSiteId: finalDisposalSite.finalDisposalSiteId,
                  finalDisposalSiteName: finalDisposalSite.finalDisposalSiteName,
                  addressInfo: {
                    zipCode: finalDisposalSite.addressInfo.zipCode,
                    prefecturesId: finalDisposalSite.addressInfo.prefecturesId,
                    selectedPrefectureTemp: finalDisposalSite.addressInfo.prefecturesName,
                    address: finalDisposalSite.addressInfo.address,
                    buildingName: finalDisposalSite.addressInfo.buildingName,
                  },
                  tel: finalDisposalSite.tel,
                  finalDisposalStoreCode: finalDisposalSite.finalDisposalStoreCode,
                }
              }
            );
            const finalDisposalSiteValidate = d.finalDisposalSiteList.map(
              () => {
                return {
                  finalDisposalSiteName: { isValid: true, errMsg: "" },
                  zipCode: { isValid: true, errMsg: "" },
                  prefecture: { isValid: true, errMsg: "" },
                  address: { isValid: true, errMsg: "" },
                  building: { isValid: true, errMsg: "" },
                  tel: { isValid: true, errMsg: "" },
                  finalDisposalStoreCode: { isValid: true, errMsg: "" },
                }
              }
            );
            this.form = [
              ...this.form,
              {
                formId: newId,
                disposalSiteId: info.id,
                disposalSiteName: info.name,
                addressInfo: {
                  zipCode: info.zipCode,
                  prefecturesId: info.prefecturesId,
                  selectedPrefectureTemp: info.prefecturesName,
                  address: info.address,
                  buildingName: info.buildingName,
                },
                tel: info.tel,
                fax: info.fax,
                jwnetContractNo: d.jwnetContractNo,
                ediKey: d.ediKey,
                disposalStoreCode: d.disposalStoreCode,
                publicConfirmationNo: d.publicConfirmationNo,
                disposalSiteLicenceList: disposalSiteLicenceList,
                disposalSiteData: [], // not post
                finalDisposalSiteList:  finalDisposalsiteList,
              },
            ];

            this.changeAddressInfoHandler(newId, false);

            // validateも追加
            this.validate = [
              ...this.validate,
              {
                formId: newId,
                disposalSiteName: { isValid: true, errMsg: "" },
                zipCode: { isValid: true, errMsg: "" },
                prefecturesId: { isValid: true, errMsg: "" },
                address: { isValid: true, errMsg: "" },
                buildingName: { isValid: true, errMsg: "" },
                tel: { isValid: true, errMsg: "" },
                fax: { isValid: true, errMsg: "" },
                jwnetContractNo: { isValid: true, errMsg: "" },
                ediKey: { isValid: true, errMsg: "" },
                disposalStoreCode: { isValid: true, errMsg: "" },
                publicConfirmationNo: { isValid: true, errMsg: "" },
                finalDisposalSiteList: finalDisposalSiteValidate
              },
            ];
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 所属による名称が重複チェックAPI
    checkDuplicationName(duplicationNameCheckInfoList) {
      return axios
          .post(API_URL.DUPLICATION.NAME, {duplicationNameCheckInfoList : duplicationNameCheckInfoList})
          .then((res) => {
            this.duplicatedNameInfoList = res.data.duplicatedNameInfoList;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
